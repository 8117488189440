<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.42 25.5"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><polygon class="cls-1" points="18.02 25.25 0.25 25.25 0.25 0.25 18.02 0.25 18.02 6.22 15.21 6.22 15.21 3.05 3.05 3.05 3.05 22.45 15.21 22.45 15.21 19.28 18.02 19.28 18.02 25.25"/><polygon class="cls-1" points="21.53 6.92 19.66 9.01 22.28 11.35 10.57 11.35 10.57 14.15 22.28 14.15 19.66 16.49 21.53 18.58 28.05 12.75 21.53 6.92"/></g></g></svg>
</template>

<script>
export default {
  name: 'logout',
}
</script>

<style scoped lang="scss">
  .cls-1{
    fill:#fff;
    stroke:#323232;
    stroke-miterlimit:10;
    stroke-width:0.5px;
  }
</style>