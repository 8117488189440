<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.43 22.43"><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path d="M11.22.89a10.33,10.33,0,1,1-7.3,3,10.29,10.29,0,0,1,7.3-3m0-.89A11.23,11.23,0,0,0,0,11.22a11.22,11.22,0,0,0,19.15,7.93A11.22,11.22,0,0,0,11.22,0Z"/><path d="M12.38,10.05V5.58a1.17,1.17,0,0,0-2.33,0v4.47H5.58a1.17,1.17,0,0,0,0,2.33h4.47v4.47a1.17,1.17,0,1,0,2.33,0V12.38h4.47a1.17,1.17,0,1,0,0-2.33Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'addCircle',
}
</script>

<style lang="scss" scoped>

</style>