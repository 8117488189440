<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.56 7.48"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M12.17,2l-5.1,5.1a1.09,1.09,0,0,1-1.58,0L.39,2A1.05,1.05,0,0,1,.29.5L.49.31a.83.83,0,0,1,1.24,0C3,1.5,4.25,2.73,5.49,4A1.11,1.11,0,0,0,7.07,4C8.31,2.73,9.6,1.5,10.84.26c.34-.4.84-.3,1.24,0l.19.19A1.05,1.05,0,0,1,12.17,2Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'sort',
}
</script>

<style scoped lang="scss">
  .cls-1 { 
    fill-rule:evenodd;
  }
</style>