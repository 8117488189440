<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.87 22.88"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M11.59,3.83h0a.67.67,0,0,0-.67.67v8L5.65,11.1a.67.67,0,0,0-.83.47h0a.69.69,0,0,0,.48.83l5.62,1.52h0l1.35.31V12.9h0V4.5a.68.68,0,0,0-.68-.67Z"/><path class="cls-1" d="M11.44,1.74a9.7,9.7,0,1,1-9.7,9.7,9.69,9.69,0,0,1,9.7-9.7ZM0,12.51A11.44,11.44,0,0,0,22.83,10.4,11.44,11.44,0,1,0,0,12.51Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'mini_clock',
}
</script>

<style scoped>
  .cls-1{
    fill-rule:evenodd;
  }
</style>
