import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)

const requireAuth = () => (from, to, next) => {
  if (store.state.accessToken !=null && store.state.accessToken !='null' ){
    return next() // isAuth === true면 페이지 이동
  } else{
    console.log('go login')
    return next({path:'/login'}) // isAuth === false면 다시 로그인 화면으로 이동
  }
}

const routes = [
  {
    path: '/',
    name: 'dashboard',
    beforeEnter:requireAuth(),
    component: () => import('../views/dashboard.vue'),
    meta: {
      title: 'APS',
      headerActive: true,
    }
  },
  {
    path:'/logout',
    name:'logout',
    component: () => import('../views/newLogin.vue'),
    meta: {
      title: '로그인',
      headerActive: false,
    }
  }, 
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/newLogin.vue'),
    meta: {
      title: '로그인',
      headerActive: false,
    }
  },
  {
    path: '/machineWorkHour',
    name: 'machineWorkHour',
    beforeEnter:requireAuth(),
    component: () => import('../views/machineWorkingHour.vue'),
    meta: {
      title: '기계별 조업 시간 달력',
      headerActive: true,
    }
  },
  {
    path: '/companyWorkHour',
    name: 'companyWorkHour',
    beforeEnter:requireAuth(),
    component: () => import('../views/companyWorkingHour.vue'),
    meta: {
      title: '회사 조업 시간 달력',
      headerActive: true,
    }
  },
  {
    path: '/createProcess',
    name: 'createProcess',
    beforeEnter:requireAuth(),
    component: () => import('../views/createProcess.vue'),
    meta: {
      title: '공정 스케줄 등록',
      headerActive: true,
    }
  },
  {
    path: '/customerManagement',
    name: 'customerManagement',
    beforeEnter:requireAuth(),
    component: () => import('../views/customerManagement.vue'),
    meta: {
      title: '고객사 관리',
      headerActive: true,
    }
  },
  {
    path: '/machineScheduleManage',
    name: 'machineScheduleManage',
    beforeEnter:requireAuth(),
    component: () => import('../views/machineScheduleManage.vue'),
    meta: {
      title: '기계 스케줄 관리',
      headerActive: true,
    }
  },
  {
    path: '/operationStatus',
    name: 'operationStatus',
    beforeEnter:requireAuth(),
    component: () => import('../views/machineOperationStatus.vue'),
    meta: {
      title: '기계 가동상황',
      headerActive: true,
    }
  },
  {
    path: '/nonoperating',
    name: 'nonoperating',
    beforeEnter:requireAuth(),
    component: () => import('../views/accumplishedNonOperatingTime.vue'),
    meta: {
      title: '누적 비가동시간',
      headerActive: true,
    }
  },
  {
    path: '/processSchedule',
    name: 'processSchedule',
    beforeEnter:requireAuth(),
    component: () => import('../views/processScheduleManage.vue'),
    meta: {
      title: '공정 스케줄 관리',
      headerActive: true,
    }
  },
  {
    path: '/toolChange',
    name: 'toolChange',
    beforeEnter:requireAuth(),
    component: () => import('../views/toolChangeManage.vue'),
    meta: {
      title: '공구교체관리',
      headerActive: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  
  routes
})

export default router
