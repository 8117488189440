<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 45.11 46.36"
  >
    <defs></defs>
    <g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M22.56,0C16.33,0,11.28,3.35,11.28,10.73s5.21,13.42,11.28,13.42,11.27-6,11.27-13.42S28.79,0,22.56,0ZM9.79,29.94a18,18,0,0,0-9,5.61A3.75,3.75,0,0,0,0,37.84v4.49a4.29,4.29,0,0,0,4.51,4H40.6a4.29,4.29,0,0,0,4.51-4V37.84A3.75,3.75,0,0,0,44,35.23a18.24,18.24,0,0,0-8.72-5.29,44.33,44.33,0,0,0-12.76-1.76A44.4,44.4,0,0,0,9.79,29.94Z"/></g></g>
  </svg>
</template>

<script>
export default {
  name: 'loginProfile',
}
</script>

<style lang="scss" scoped>
  .cls-1 { 
    fill:#fff;
    fill-rule:evenodd; 
  }
</style>