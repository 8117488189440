<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.68 14.4"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M2.16,14.4h8.36a.92.92,0,0,0,.92-.91V4.38L12,3.86V3H.73v.83l.51.52v9.11A.92.92,0,0,0,2.16,14.4Zm6.52-10h1V13h-1Zm-2.87,0h1V13h-1Zm-2.87,0H4V13h-1Z"/><path class="cls-1" d="M12.68,2.54V2.15a.87.87,0,0,0-.88-.88H8V1.08A1.08,1.08,0,0,0,7,0H5.72A1.08,1.08,0,0,0,4.64,1.08v.19H.88A.87.87,0,0,0,0,2.15v.39ZM5.42,1.08a.3.3,0,0,1,.3-.31H7a.3.3,0,0,1,.3.31v.19H5.42Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'delete',
}
</script>

<style scoped lang="scss">
  .cls-1{fill:#fff;}
</style>