<template>
  <v-app :class="{ 'header--active': $route.meta.headerActive }">
    <mainHeader v-if="$route.meta.headerActive"></mainHeader>
    <router-view/>
  </v-app>
</template>

<script>
import mainHeader from './components/header.vue';
import Vue from 'vue';

export default {
  name: 'App',
  data() {
    return {
    };
  },
   created(){
      document.title="APS";
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      var link1 = document.createElement('link');
      link1.rel = 'apple-touch-icon';
      document.getElementsByTagName('head')[0].appendChild(link1);  
     
      link.href = "./APS.png";
      link1.href = "./APS.png";
    },
  components: {
    mainHeader,
  },
  mounted() {
    this.$cnc.setCncList(this.process_list);
  },
  methods:{
  }
};
</script>

<style lang="scss">
  html { overflow-y: auto !important; }
  
  .v-application {
    min-height: 100vh;

    &.header--active {
      padding-top: 70px !important;
    }

    .v-application--wrap {
      min-height: 100%;
    }
  }
</style>
