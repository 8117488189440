<template>
  <div id="header">
    <v-toolbar
      class="main-header"
      height="70"
      dark flat tile
    >
      <div class="header-yhs-logo">
        <router-link to="/">
          <img src="@/assets/img/yhs_logo.svg" alt="YHS BEARING">
        </router-link>
      </div>

      <v-list class="header-menu">
        <v-list-item
          v-for="menu, index in menu_list"
          class="header-menu__i"
          :class="{ 'menu--have-children' : menu.children.length != 0 }"
          :key="'menu' + menu.title"
          :to="menu.path"
        >
          {{ menu.title }}

          <p class="menu__expand-icon" v-if="menu.children.length != 0">
            <img src="@/assets/img/expand_icon.svg" alt="메뉴 확장 세모 아이콘">
          </p>

          <div class="sub-menu">
            <ul class="sub-menu__inner">
              <li 
                class="sub-menu__i"
                v-for="sub, i in menu.children"
                :key="'menu' + index + '_sub' + i "
              >
                <router-link :to="sub.path">
                  {{ sub.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </v-list-item>
      </v-list>

      <v-spacer></v-spacer>

      <v-btn 
        to="/logout"
        class="header-logout" 
        :ripple="false"
        fab small plain
      >
        <v-icon>$logout</v-icon>
      </v-btn>

    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: 'mainHeader',
  data() {
    return {
      menu_list: [
        {
          title: '일정 관리',
          path: '',
          children: [
            {
              title: '회사 조업시간 달력',
              path: '/companyWorkHour',
              child: [],
            },
            {
              title: '기계별 조업시간 달력',
              path: '/machineWorkHour',
              child: [],
            },
          ],
        },
        {
          title: '기계 관리',
          path: '',
          children: [
            {
              title: '기계 스케줄 관리',
              path: '/machineScheduleManage',
              child: [],
            },
             {
              title: '기계 실시간 가동 상황',
              path: '/operationStatus',
              child: [],
            },
            {
              title: '공정별 누적 비가동시간',
              path: '/nonoperating',
              child: [],
            },

          ],
        },
        
        {
          title: '공정 관리',
          path: '',
          children: [
            {
              title: '공정 스케줄 등록',
              path: '/createProcess',
              child: [],
            },
             {
              title: '공정 스케줄 관리',
              path: '/processSchedule',
              child: [],
            },
            {
              title: '발주처 관리',
              path: '/customerManagement',
              child: [],
            },

          ],
        },
      ]
    };
  },
  mounted() {
  },
  methods:{
  }
};
</script>

<style lang="scss" scoped>
  @import '~@/assets/css/global.scss';

  #header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }

  ::v-deep .main-header {
    width: 100%;
    background-color: $header_black !important;

    .v-toolbar__content { 
      padding: 0 map-get($vw, 45) 0 map-get($vw, 60);
    }

    .header-yhs-logo {

      a {
        display: block;
        padding: 16px 50px 16px 0;
      }
      img {
        display: block;
        width: 145px;
      }
    }

    .header-menu {
      height: 100%;
      padding: 0; 
      
      &__i {
        @extend .flex_row;

        display: inline-flex;
        height: 100%;
        font-size: 20px;
        font-family: $ptd !important;
        font-weight: 500;
        padding: 2px 48px 0;
        cursor: pointer;
        position: relative;
        background-color: $header_black;

        &.menu--have-children {
          padding: 2px 28px 0 48px;
        }
        &::before { display: none; }
        &:hover,
        &.v-list-item--active {
          background-color: black !important;
        }
        &:hover {

          .sub-menu { display: block;}
        }

        .menu__expand-icon {
          padding: 0 0 1px 12px;
          
          img {
            display: block;
            width: 8px;
            height: auto;
          }
        }

        // &.menu--have-children {}
        &:last-child {
          margin-right: 0;
        }
      }

      .sub-menu {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        background: #3e3a39;

        &__i {

          &:hover {
            background-color: black;
          }

          a {
            display: block;
            font-family: $ptd !important;
            font-size: 15px;
            font-weight: 500;
            white-space: nowrap;
            padding: 20px 48px;
            color: white;
          }
        }
      }
    }
    .header-logout {
      min-width: unset !important;
      padding: 0 !important;

      &::before {
        opacity: 0 !important;
      }
      .v-btn__content {
        opacity: 1 !important;
      }
    }
  }
</style>
