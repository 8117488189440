<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.84 13.68"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M11.79,0a.31.31,0,0,0-.24.1L5.15,6.49,4.69,9.15l2.6-.43L13.74,2.3a.37.37,0,0,0,0-.5L12,.1a.32.32,0,0,0-.25-.1Z"/><path class="cls-1" d="M.85.51A.85.85,0,0,0,0,1.36V12.83a.85.85,0,0,0,.85.85h9.86a.85.85,0,0,0,.85-.85V8.4H9.86V12H1.7V2.21H7.57V.51Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'modify',
}
</script>

<style lang="scss" scoped>
  .cls-1{fill:#fff;}
</style>