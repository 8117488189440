<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.98 12.96"><defs></defs><g id="레이어_2" data-name="레이어 2"><g id="레이어_1-2" data-name="레이어 1"><path class="cls-1" d="M6.49,8.33l4.27,4.26a1.34,1.34,0,0,0,1.84,0,1.28,1.28,0,0,0,.38-.92,1.3,1.3,0,0,0-.38-.93L8.34,6.48,12.6,2.21A1.26,1.26,0,0,0,13,1.29,1.28,1.28,0,0,0,12.6.37a1.34,1.34,0,0,0-1.84,0L6.49,4.63,2.23.37A1.35,1.35,0,0,0,.38.37,1.32,1.32,0,0,0,0,1.29a1.3,1.3,0,0,0,.38.92L4.65,6.48.38,10.74a1.34,1.34,0,0,0-.38.93,1.32,1.32,0,0,0,.38.92,1.35,1.35,0,0,0,1.85,0Z"/></g></g></svg>
</template>

<script>
export default {
  name: 'close',
}
</script>

<style lang="scss" scoped>
  .cls-1{fill:#fff;}
</style>